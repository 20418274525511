.top-row {
  display: flex;
  justify-content: center; /* Center horizontally */
  margin-bottom: 20px;
}

.bottom-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

/*Input box*/
.loan-calculator-container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.input-group-container {
  display: flex; /* Change to flex */
  align-items: center; /* Align items vertically in the center */
  gap: 20px;
  flex-wrap: wrap; /* Allow items to wrap onto multiple lines if necessary */
}

.input-group {
  margin-bottom: 15px;
  flex: 0 0 calc(33.333% - 20px); /* Set the width of each input group to 33.333% minus the gap */
}

label {
  display: block;
  margin-bottom: 5px;
  height: 45px; /* Set a consistent height for the labels */
}

input[type="text"] {
  width: calc(100% - 10px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}


button {
  padding: 12px 24px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}


/* Tables */
.custom-table {
  width: 300px; /* 20px margin on both sides */
  margin: 40px auto 20px auto; /* Center the table with margin on top and bottom */
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px; /* Rounded corners for the table */
  overflow: hidden; /* Clip the shadows inside the rounded corners */
}

.custom-table th,
.custom-table td {
  padding: 12px; /* Adjust overall padding */
  text-align: left;
}

.custom-table th {
  background-color: #718096; /* Grey/Blue-ish */
  color: #000000; /* Black */
  font-weight: bold;
}

.custom-table th:first-child,
.custom-table td:first-child {
  padding-left: 16px; /* Adjust left padding for columns */
}

.custom-table th:last-child,
.custom-table td:last-child {
  padding-right: 0;
}

.custom-table .even-row,
.custom-table .odd-row {
  background-color: #ffffff; /* White */
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Darker shadow effect */
  border-radius: 0; /* Make corners square */
  border-bottom: 10px solid transparent; /* Gap between rows */
}






/* Tables */
.custom-table2 {
  width: 1000px; /* 20px margin on both sides */
  margin: 40px auto 20px auto; /* Center the table with margin on top and bottom */
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px; /* Rounded corners for the table */
  overflow: hidden; /* Clip the shadows inside the rounded corners */
}

.custom-table2 th,
.custom-table2 td {
  padding: 12px; /* Adjust overall padding */
  text-align: left;
}

.custom-table2 th {
  background-color: #718096; /* Grey/Blue-ish */
  color: #000000; /* Black */
  font-weight: bold;
}

.custom-table2 th:first-child,
.custom-table2 td:first-child {
  padding-left: 16px; /* Adjust left padding for columns */
}

.custom-table2 th:last-child,
.custom-table2 td:last-child {
  padding-right: 0;
}

.custom-table2 .even-row,
.custom-table2 .odd-row {
  background-color: #ffffff; /* White */
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Darker shadow effect */
  border-radius: 0; /* Make corners square */
  border-bottom: 10px solid transparent; /* Gap between rows */
}


/* CSS for LoanOverview component */

.loan-overview {
  font-family: Arial, sans-serif;
  color: #333;
  margin-left: 1rem;
}

.loan-overview h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.loan-overview p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.loan-overview .numbers {
  font-size: 1.2rem;
  color: #007bff; /* Blue color for numbers */
}

.loan-overview .columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 20px;
}

.loan-overview .column {
  flex: 1.; /* Adjust the flex value to make the columns wider */
}

@media (max-width: 768px) {
  .loan-overview .columns {
    flex-direction: column;
  }
}



