/* src/components/Resume.css */
.resume-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.profile-image-wrapper {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}

.profile-section {
  flex: 0 0 auto;
  margin-right: 20px;
}

.profile-image {
  width: 400px;
  height: auto;
  border-radius: 50%;
  margin-left: 0 auto;
}

.profile-edu-container {
  display: flex; /* Change to flex */
  align-items: center;
}

.experience-items {
  flex: 1;
}

.experience-item {
  flex: 1;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
}

.experience-item h2 {
  margin-top: 0;
}

.experience-item p {
  margin-bottom: 10px;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .resume-container {
    flex-direction: column; /* Change layout to column */
  }

  .profile-image-wrapper {
    width: 80vw;
    margin: 0 auto;
  }

  .profile-image {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }

  .profile-section {
    margin-bottom: 20px; /* Add space between profile image and resume items */
  }

  .profile-edu-container {
    flex-direction: column; /* Change layout to column */
    align-items: flex-start; /* Align items to the start */
  }
}
