/* src/components/Resume.css */
.speciale-image {
  display: block;
  margin: 10px auto; /* Center the image horizontally */
  width: auto;
  height: auto;
}

.speciale-text {
  margin-left: 10vw;
  margin-right: 5vw
}

  

/* Media query for smaller screens */
@media (max-width: 600px) {
  .speciale-image {
    width: 100%; /* Set the width to 100% for smaller screens */
  }
}