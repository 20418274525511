.chat-header {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    display: flex;
    justify-content: space-between;
  }

.chatContainer {
  position: 'relative'; 
  height: 80vh;
  width: 50vh; 
}
  
  .close-button {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
  }
  
  .chatbot-popup {
    position: fixed;
    bottom: 40px;
    right: 40px;
    margin-right: 20px; /* Adjust margin from right */
    margin-bottom: 20px; /* Adjust margin from bottom */
    box-shadow: 10px 10px 10px #ccc;
  }

  .chatbot-popup:hover {
    cursor: pointer;
  }

  .chatbot-image {
    width: 150px;
    height: 150px
  }
  
  @media (max-width: 600px) {
    .chatContainer {
      height: 80vh;
      width: 80vw; 
    }

    .chatbot-popup {
      position: fixed;
      bottom: 10px;
      right: 10px;
      margin-right: 20px; /* Adjust margin from right */
      margin-bottom: 20px; /* Adjust margin from bottom */
      box-shadow: 10px 10px 10px #ccc;
    }

    .chatbot-image {
      width: 100px;
      height: 100px
    }
  
  }